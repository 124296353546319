/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/layout.css";

// Auth
import Amplify from "aws-amplify";
import clientConfig from "./client-config";
let awsConfig = {};
try {
  awsConfig = require("./src/aws-exports");
} catch (error) {
  // Do nothing. This mess is only to turn a conditional import from an error into a warning
}
if (clientConfig.auth) {
  Amplify.configure(awsConfig);
}
// End Auth
